import { useEffect, useState } from 'react';
import { Button, InfoBox, Modal } from 'src/components';

import { ActionButton } from '../../styles';
import { useUserActions, useUserState } from 'src/contexts/UserContext';
import { usePreviousValue } from 'src/hooks';
import { useBarecancel } from 'src/services';
import { SubscriptionProduct } from 'src/models/SubscriptionProduct';

function checkOneYearPlan(subscription: SubscriptionProduct | null | undefined) {
  if (subscription == null) {
    return null;
  }
  const id = subscription.id;
  if (id === 'fff40f24-53f0-4ef3-b375-728bf9b285f5' || id === '569c8fcc-d492-4a7d-b02f-04645b3cdbdd') {
    return subscription.price / 100;
  }
  return null;
}

function checkSixMonthPlan(subscription: SubscriptionProduct | null | undefined) {
  if (subscription == null) {
    return null;
  }
  const id = subscription.id;
  if (id === '449d107d-6127-4571-923b-40b054a869f6') {
    return subscription.price / 100;
  }
  return null;
}

function getStopChurnCode(subscription: SubscriptionProduct | null | undefined) {
  if (subscription?.id === 'a473463f-626b-44a2-808a-4048f3c0835a') {
    return 'ZOSTAJE';
  }
  if (subscription?.id === '20fbe72e-f117-431a-b67f-f372f1a5f3ee') {
    return 'ZOSTAJE1';
  }
  return null;
}

export const CancelSubscription: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [showPromoDialog, setShowPromoDialog] = useState(false);
  const { cancelSubscription } = useUserActions();
  const {
    cancelSubscriptionStatus: status,
    isCancellationPending,
    isTrialing,
    user,
  } = useUserState();
  const openBarecancel = useBarecancel((user as unknown as {stripeId: string}).stripeId);

  const productNotActive = user.subscription?.subscriptionProduct?.active !== true;
  const oneYearWarnPrice = checkOneYearPlan(user.subscription?.subscriptionProduct);
  const sixMonthWarnPrice = checkSixMonthPlan(user.subscription?.subscriptionProduct);
  const stopChurnCode = getStopChurnCode(user.subscription?.subscriptionProduct);
  const showStopChurn = stopChurnCode != null;

  const prevStatus = usePreviousValue(status);
  useEffect(() => {
    if (status === 'idle' && prevStatus === 'pending') {
      setShowDialog(false);
      setShowPromoDialog(false);
      // if (isCancellationPending) {
      //   openBarecancel();
      // }
    }
  }, [status, prevStatus, isCancellationPending, openBarecancel]);

  function onCancel() {
    if (oneYearWarnPrice != null || sixMonthWarnPrice != null) {
      setShowDialog(false);
      setShowPromoDialog(true);
    } else {
      cancelSubscription();
    }
  }

  let content: JSX.Element;
  if (isTrialing) {
    content = (
      <>
        <p>Czy na pewno chcesz anulować okres próbny swojej subskrypcji?</p>
        <p>Anulowanie spowoduje natychmiastową utratę dostępu do materiałów na naszej platformie.</p>
      </>
    );
  } else if (oneYearWarnPrice != null) {
    content = (
      <>
        <p><strong>UWAGA: Obecnie korzystasz z abonamentu za {oneYearWarnPrice} zł, który nie jest już dostępny w naszej stałej ofercie. Aktualna cena rocznego abonamentu to 597 zł. Anulacja subskrypcji uniemożliwi w przyszłości powrót do obecnej niższej ceny. Dopóki nie przerwiesz subskrypcji, możesz cieszyć się  preferencyjną ceną.</strong></p>
        <p>Czy nadal chcesz anulować subskrypcję?</p>
        <p>Po zakończeniu obecnego okresu rozliczeniowego utracisz dostęp do materiałów na naszej platformie, a także utracisz swój postęp.</p>
      </>
    );
  } else if (sixMonthWarnPrice != null) {
    content = (
      <>
        <p><strong>UWAGA: Obecnie korzystasz z abonamentu 6-miesięcznego za 170 zł, który nie jest już dostępny w naszej stałej ofercie. Anulacja subskrypcji uniemożliwi w przyszłości powrót do obecnego planu. Dopóki nie przerwiesz subskrypcji, możesz pozostać na planie półrocznym w  preferencyjnej cenie 170 zł.</strong></p>
        <p>Czy nadal chcesz anulować subskrypcję?</p>
        <p>Po zakończeniu obecnego okresu rozliczeniowego utracisz dostęp do materiałów na naszej platformie, a także utracisz swój postęp.</p>
      </>
    );
  } else if (productNotActive) {
    content = (
      <>
        <p><strong>UWAGA: Obecnie korzystasz z abonamentu, który nie jest już dostępny w naszej stałej ofercie. Anulacja subskrypcji uniemożliwi w przyszłości powrót do obecnej niższej ceny. Dopóki nie przerwiesz subskrypcji, możesz cieszyć się preferencyjną ceną.</strong></p>
        <p>Czy nadal chcesz anulować subskrypcję?</p>
        <p>Po zakończeniu obecnego okresu rozliczeniowego utracisz dostęp do materiałów na naszej platformie, a także utracisz swój postęp.</p>
      </>
    );
  } else if (stopChurnCode != null) {
    content = (
      <>
        <p>A może zostaniesz z nami jeszcze trochę? Mamy dla Ciebie 50% zniżki na kolejny miesiąc. </p>
        <p>Twój kod: <strong>{stopChurnCode}</strong></p>
        <p>Wpisz kod w zakładce Zarządzaj subskrypcją w polu Zrealizuj voucher/kod rabatowy. Aktywowana zniżka naliczy się automatycznie przy kolejnej płatności.</p>
      </>
    );
  } else {
    content = (
      <>
        <p>Czy na pewno chcesz anulować swoją subskrypcję?</p>
        <p>Po zakończeniu obecnego okresu rozliczeniowego utracisz dostęp do materiałów na naszej platformie, a także utracisz swój postęp.</p>
      </>
    );
  }

  return (
    <>
      {!isCancellationPending && (
        <ActionButton onClick={() => setShowDialog(true)}>
          Anuluj subskrypcję
        </ActionButton>
      )}
      <Modal open={showDialog}>
        <Modal.Title>Potwierdź anulowanie subskrypcji</Modal.Title>
        <Modal.Content>
          {content}
          {status === 'error' && (
            <InfoBox
              mode="error"
              message="Anulowanie subskrypcji nie powiodło się."
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            label={showStopChurn ? 'Zostaję w studio' : 'Powrót'}
            onClick={() => setShowDialog(false)}
            disabled={status === 'pending'}
          />
          <Button
            label={(productNotActive || showStopChurn) ? 'Anuluj mimo wszystko' : 'Anuluj subskrypcję'}
            onClick={onCancel}
            variant="minimal"
            disabled={status === 'pending'}
            pending={status === 'pending'}
          />
        </Modal.Actions>
      </Modal>

      <Modal open={showPromoDialog}>
        <Modal.Content>
          <p>A może zostaniesz z nami jeszcze trochę? Mamy dla Ciebie zniżkę na kolejne 3 miesiące w cenie 99 zł!!!</p>
          <p>Twój kod: <strong>ZOSTAJE99</strong></p>
          <ol>
            <li>wpisz kod w polu Zrealizuj voucher/kod rabatowy</li>
            <li>wybierz subskrypcję 3-miesięczną (musi widnieć przy niej cena 99zł, dopiero wtedy zatwierdź zmiany)</li>
            <li>po zakończeniu obecnego okresu rozliczeniowego subskrypcja zostanie zmieniona na plan 3-miesięczny, a pierwsza opłata za nią zostanie pobrana ze zniżką</li>
          </ol>
          {status === 'error' && (
            <InfoBox
              mode="error"
              message="Anulowanie subskrypcji nie powiodło się."
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            label="Zostaję w studio z kodem"
            onClick={() => setShowPromoDialog(false)}
            disabled={status === 'pending'}
          />
          <Button
            label="Chcę anulować"
            onClick={cancelSubscription}
            variant="minimal"
            disabled={status === 'pending'}
            pending={status === 'pending'}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
